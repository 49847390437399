import React from "react"
import Layout from "../../components/Layout/Layout"
import SEO from "../../components/Seo/Seo"
import PageHeader from '../../components/Layout/PageHeader/PageHeader'
import SectionPageMargin from '../../components/Layout/Section/SectionPageMargin'
import Heading2 from '../../components/Type/Heading2'
import Heading4 from '../../components/Type/Heading4'
import Paragraph1 from '../../components/Type/Paragraph1'
import ReadyToGetStarted from '../../components/ReadyToGetStarted/ReadyToGetStarted'

const ArticlePage = ({ location }) =>
(
  <Layout>

    <SEO title="BMW M3 Competition F80 Buyers Guide"
      description="Buying a BMW M3 Competition? Read our buyers guide for prices, specs and advice on how to buy a good one"
      location={location}
    />

    <PageHeader
      title="BMW M3 Competition F80 Buyers Guide"
      text="Of all the M-badged BMWs, the M3 has always had the keenest following. The various mid-sized sedans that have born that moniker over the years have blitzed roads and race tracks, dazzling drivers with their magical blend of fearsome speed and engaging dynamics. Rivals have occasionally come close to matching the M3’s glory, but none have such a devoted fanbase. With such popularity comes rock solid residuals.The shortage of new car supplies caused by Covid has bolstered used car prices generally, but values of the 991-series Porsche 911 Carrera GTS haven’t merely been strong, they’ve increased during the past eight months."
    />

    <SectionPageMargin>

      <Heading2>Buying a BMW M3 Competition? Read our buyers guide for prices, specs and advice on how to buy a good one</Heading2>

      <Heading4>Market Analysis</Heading4>

      <Paragraph1>
        The BMW M3 we’re looking at here is the F80-series car and the LCI version of it – LCI stands for Life Cycle Impulse, a faintly ridiculous term for a facelift. What’s important about the F80 M3’s 2016 facelift, though, is that included within its upgrades was an all-new option, the Competition Package. You can read of what it comprises further on, but the important thing is that the Competition Package made for a much better M3. Over time the word ‘Package’ has been relegated to the sidelines, and cars thus equipped are known simply as an M3 Competition – evocative, yes?
      </Paragraph1>

      <Paragraph1>
        The BMW M3 Competition’s awesome abilities and consequent exalted status, combined with the general upswing in secondhand car values precipitated by Covid and new car supply shortages, mean that there are no cheap ones. Even a 2016 car with the other side of 100,000km on the clock hovers around the $70,000 mark, based on Summon’s data, collected from the past 12 months of private and dealer sale ads in Australia (and excluding government charges). And while 2016 models do offer the best value amongst M3 Competitions, you’ll still be paying north of $90K for an example with less than 20,000km on the dial.
      </Paragraph1>

      <Paragraph1>
        A year younger, though, with that sort of mileage, and the average price rises to $108,668, according to our data. And a sub-20,000km 2018 model – the last year that the F80 LCI M3 Competition was made – will set you back around $111,500. Against those figures, a 2016 car seems like a sensible choice, as long as you catch sight of a full BMW service history. Another way to look at is, that for $93K you can have a 20,000km 2016 model, or a 2018 example with three times the mileage.
      </Paragraph1>

      <Paragraph1>
        Whether you decide upon a BMW M3 Competition from 2016, 2017 or 2018, there’s a generally gentle decline in value every 20,000km or so: oddly, though, our figures show an uptick in prices for 2017 models in the 80,000km+ bracket. Bear in mind that when we say ‘gentle decline’ we really don’t mean dip – the average price for a 100,000km+ 2017 M3 Competition stood at nearly $75K.

      </Paragraph1>



      <Heading4>What is it?</Heading4>

      <Paragraph1>
        The F80 LCI BMW M3 Competition is one of the world’s fastest, most exciting and most desirable four-door high-performance sedans. It’s powered by a rev-hungry in-line six-cylinder twin-turbo petrol engine that belts out a mighty 331kW in M3 Competition guise, together with an extraordinary 550Nm of torque. Its extreme acceleration, either from standstill – it will do 0-100km/h in 4.0sec dead with the auto ’box – or in-gear, especially in the mid-range, is so ferocious that passengers will either laugh nervously and spontaneously, or swear loudly and profusely.
      </Paragraph1>

      <Paragraph1>
        Although the M3 Competition is commonly considered to be a model in its own right, it is in fact an F80 LCI M3 with BMW’s optional Competition Package. The introduction of the Competition Package coincided with the car’s 2016 facelift – or Life Cycle Impulse, as BMW calls it, hence LCI – making M3s thus equipped even more desirable: the facelift included a spruced-up interior, a new iDrive vehicle setup, monitoring and infotainment system, and LED tail-lights.
      </Paragraph1>

      <Paragraph1>
        The Competition Package features a number of mechanical and technical upgrades that unleash the M3’s full dynamic potential. The Competition has more power than the standard M3, a tweaked exhaust system, new springs, electronically controlled adjustable dampers, new front and rear anti-roll bars, and revised calibration for its Active M rear differential. Visually there are some black exterior trim pieces, together with a roof panel in carbonfibre-reinforced plastic to lower the centre of gravity for improved handling. Cars with the optional sunroof lose that talking point roof material, however. Another popular option for the M3 Competition are M 666 20-inch cross-spoke alloy wheels in either silver or black. Inside the car are some fabulous ‘ventilated’ sports seats and seatbelts with tri-colour M stitching.
      </Paragraph1>

      <Paragraph1>
        A less often seen option is carbon-ceramic brake discs that were $15K new. If you’re a trackday fiend then they might be worth hunting down, but the rest of us will be just fine with the M3 Competition’s standard steel discs.
      </Paragraph1>

      <Paragraph1>
        The M3 Competition was available from new with either a six-speed manual gearbox or a seven-speed M DCT dual-clutch sports automatic with paddleshift manual override: not many Aussie buyers opted for the manual – around 3% – given that the M DCT is so capable during hard driving and so convenient for everyday motoring.
      </Paragraph1>


      <Heading4>What to look out for</Heading4>
      <Paragraph1>
        <strong>Engine:</strong> To date the M3 Competition’s engine hasn’t exposed any weaknesses, but given its complexity, be sure that the service schedule has been adhered to.
      </Paragraph1>

      <Paragraph1>
        <strong>Body and paint:</strong> Thanks to its enormously wide tyres throwing up road grit at high velocity, some cars have suffered from ‘gravel rash’ along the sills and rear valance. Older cars can suffer from ‘lazy’ boot lids.
      </Paragraph1>

      <Paragraph1>
       <strong>Suspension, brakes and tyres:</strong> It’s too early yet for the GTS to start suffering from the degradation of suspension bushes, so if you see uneven tyre wear it could be a sign the suspension geometry has been knocked out of kilter after striking a kerb or pothole.
      </Paragraph1>

      <Paragraph1>
        <strong>Interior:</strong> Although well built, the M3 Competition can suffer squeaky doors and seats, together with rattly trim.
      </Paragraph1>

      <Paragraph1>
        <strong>Modifying:</strong> The M3 Competition is ripe for, and responsive to, aftermarket tuning modifications. If the car you’re looking at has some, research the companies that have done the work to ensure high quality. If in doubt, ask that the car be returned to standard.
      </Paragraph1>

    </SectionPageMargin>

    <ReadyToGetStarted />

  </Layout>

)

export default ArticlePage
